import React from "react"
import { withRouter } from "react-router-dom"
import { Menu, message } from "antd"
import { UserOutlined, LogoutOutlined } from "@ant-design/icons"
import * as Commons from "common/common"

const Topbar = props => {
  const { driver, history } = props

  const logout = () => {
    Commons.axiosInstance
      .get(Commons.apiLogout)
      .then(response => {
        if (response.status === 200) {
          message.success(Commons.successLogoutMsg)
          localStorage.setItem(Commons.WEB_ID, "")
          history.push(Commons.loginURL)
        }
      })
      .catch(error => {
        if (error.response.status === 401) {
        } else if (error.response.status === 500) {
          message.error(Commons.errorSystemMsg)
        }
      })
  }

  const handleClick = event => {
    switch (event.key) {
      case "logout":
        logout()
        break
      default:
        break
    }
  }

  return (
    <div>
      <Menu mode="horizontal" onClick={handleClick} selectable={false}>
        <Menu.Item
          className="float-left"
          key="profile"
          icon={<UserOutlined style={{ fontSize: 18 }} />}
        >
          {driver}
        </Menu.Item>
        <Menu.Item
          className="float-right"
          key="logout"
          icon={<LogoutOutlined style={{ fontSize: 18 }} />}
          danger
        >
          ログアウト
        </Menu.Item>
      </Menu>
    </div>
  )
}

export default withRouter(Topbar)
