import React, { useState, useEffect, useCallback } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Button,
  Card,
  Col,
  // DatePicker,
  Divider,
  Empty,
  Form,
  message,
  Modal,
  Row,
  Select,
  Tabs,
  Table,
  // TimePicker,
} from "antd"
import { DatePicker as DatePickerM, List } from "antd-mobile"
import {
  ExclamationCircleFilled,
  CheckCircleFilled,
  CheckOutlined,
  CloseOutlined,
} from "@ant-design/icons"
import styled from "styled-components"
import { useSwipeable } from "react-swipeable"
import moment from "moment"
import "moment/locale/ja"
import * as Commons from "common/common"

moment.locale("ja")

const CustomRedButton = styled(Button)`
  & {
    background-color: #ff7875;
    border-color: #ff4d4f;
  }

  &:hover {
    background-color: #ff7875;
    border-color: #ff4d4f;
  }

  &:focus {
    background-color: #ff7875;
    border-color: #ff4d4f;
  }

  &:active {
    background-color: #ff7875;
    border-color: #ff4d4f;
  }
`

const CustomGreenButton = styled(Button)`
  & {
    background-color: #95de64;
    border-color: #73d13d;
  }

  &:hover {
    background-color: #95de64;
    border-color: #73d13d;
  }

  &:focus {
    background-color: #95de64;
    border-color: #73d13d;
  }

  &:active {
    background-color: #95de64;
    border-color: #73d13d;
  }
`

const CustomGreyButton = styled(Button)`
  & {
    background-color: #d9d9d9;
    border-color: #bfbfbf;
  }

  &:hover {
    background-color: #d9d9d9;
    border-color: #bfbfbf;
  }

  &:focus {
    background-color: #d9d9d9;
    border-color: #bfbfbf;
  }

  &:active {
    background-color: #d9d9d9;
    border-color: #bfbfbf;
  }
`

const CustomTable = styled(Table)`
  .ant-table.ant-table-small {
    font-size: 13px;
  }
`

const Home = props => {
  const {
    history,
    dailyCheck,
    setDailyCheck,
    showLoadingPageSpin,
    hideLoadingPageSpin,
  } = props
  const isMountedRef = Commons.useIsMountedRef()
  const { TabPane } = Tabs
  const [startForm] = Form.useForm()
  const [endForm] = Form.useForm()

  const checkVariables = [
    "DAILY_CHECK_UNTEN_COLM_01",
    "DAILY_CHECK_UNTEN_COLM_02",
    "DAILY_CHECK_UNTEN_COLM_03",
    "DAILY_CHECK_UNTEN_COLM_04",
    "DAILY_CHECK_UNTEN_COLM_05",
    "DAILY_CHECK_UNTEN_COLM_06",
    "DAILY_CHECK_ENJIN_COLM_01",
    "DAILY_CHECK_ENJIN_COLM_02",
    "DAILY_CHECK_ENJIN_COLM_03",
    "DAILY_CHECK_ENJIN_COLM_04",
    "DAILY_CHECK_ENJIN_COLM_05",
    "DAILY_CHECK_OUT_COLM_01",
    "DAILY_CHECK_OUT_COLM_02",
    "DAILY_CHECK_OUT_COLM_03",
    "DAILY_CHECK_OUT_COLM_04",
    "DAILY_CHECK_OUT_COLM_05",
    "DAILY_CHECK_OUT_COLM_06",
    "DAILY_CHECK_OUT_COLM_07",
    "DAILY_CHECK_ETC_COLM_01",
    "DAILY_CHECK_ETC_COLM_02",
  ]

  const checkSection1 = {
    keys: [
      "DAILY_CHECK_UNTEN_COLM_01",
      "DAILY_CHECK_UNTEN_COLM_02",
      "DAILY_CHECK_UNTEN_COLM_03",
      "DAILY_CHECK_UNTEN_COLM_04",
      "DAILY_CHECK_UNTEN_COLM_05",
      "DAILY_CHECK_UNTEN_COLM_06",
    ],
    insPoints: [
      "ブレ一キ・ペダル",
      "駐車ブレーキ",
      "空気圧力計",
      "ブレ一キ・バルブ",
      "ウインド・ウォッシャー",
      "ワイバ一",
    ],
    insItems: [
      "踏みしろ、きき具合",
      "レバ一の引きしろ",
      "圧力の上がり具合",
      "バルブからの排気音",
      "※液量、噴射状態",
      "※払拭（ふっしよく）伏態",
    ],
  }

  const checkSection2 = {
    keys: [
      "DAILY_CHECK_ENJIN_COLM_01",
      "DAILY_CHECK_ENJIN_COLM_02",
      "DAILY_CHECK_ENJIN_COLM_03",
      "DAILY_CHECK_ENJIN_COLM_04",
      "DAILY_CHECK_ENJIN_COLM_05",
    ],
    insPoints: [
      "ブレ一キ、リザ一バ一タンク",
      "冷却水",
      "フアンべルト",
      "エンジンオイル",
      "原動機",
      "ワイバ一",
    ],
    insItems: [
      "液量",
      "水量",
      "※張り具合、損傷",
      "※オイルの量",
      "※液量、噴射状態",
      "※かかり具合、異音、低運及び加速の伏態",
    ],
  }

  const checkSection3 = {
    keys: [
      "DAILY_CHECK_OUT_COLM_01",
      "DAILY_CHECK_OUT_COLM_02",
      "DAILY_CHECK_OUT_COLM_03",
      "DAILY_CHECK_OUT_COLM_04",
      "DAILY_CHECK_OUT_COLM_05",
      "DAILY_CHECK_OUT_COLM_06",
      "DAILY_CHECK_OUT_COLM_07",
    ],
    insPoints: [
      "灯火装置及び方向指示器",
      "バッテリー",
      "タイャ",
      "タイャ",
      "タイャ",
      "タイャ",
      "エア・タンク",
    ],
    insItems: [
      "点灯、点滅具合、汚れ、損傷",
      "※液量",
      "空気圧",
      "亀裂、損傷",
      "異常な摩耗",
      "※溝の深さ",
      "タンクの凝水",
    ],
  }

  const checkSection4 = {
    keys: ["DAILY_CHECK_ETC_COLM_01", "DAILY_CHECK_ETC_COLM_02"],
    insPoints: ["運行において異常が認められた箇所", "車検証、保険証、記録簿"],
    insItems: ["当該箇所の異常の伏態", "備付状態"],
  }

  const [weathers, setWeathers] = useState([])
  const [checkModalVisible, setCheckModalVisible] = useState(false)
  const [currentTabKey, setCurrentTabKey] = useState("1")
  const [loadingButtonSpin, setLoadingButtonSpin] = useState(false)
  const [loadingCheckButtonSpin, setLoadingCheckButtonSpin] = useState(false)

  const { Option } = Select

  const checkDailyComplete = () => {
    if (Object.keys(dailyCheck).length !== 0) {
      const notCheckedCount = checkVariables.filter(
        check => (dailyCheck[check] || "null") === "null",
      ).length

      if (notCheckedCount === 0) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  }

  const fetchWeatherData = useCallback(() => {
    if (Object.keys(dailyCheck).length === 0) history.push(Commons.rootURL)

    showLoadingPageSpin()

    Commons.axiosInstance
      .get(Commons.apiWeather)
      .then(response => {
        if (isMountedRef.current) {
          setWeathers(response.data || [])
        }
      })
      .catch(error => {
        if (error.response.status === 403) {
          message.warning(Commons.errorSessionMsg)
          history.push(Commons.loginURL)
        } else {
          message.error(Commons.errorSystemMsg)
        }
      })
      .finally(() => {
        hideLoadingPageSpin()
      })
  }, [
    isMountedRef,
    history,
    dailyCheck,
    showLoadingPageSpin,
    hideLoadingPageSpin,
  ])

  useEffect(fetchWeatherData, [])

  const updateCheck = (key, value) => {
    if (isMountedRef.current) {
      showLoadingCheckButtonSpin()

      const putData = {
        HATTCHI_DT: dailyCheck.HATTCHI_DT,
        key: key,
        value: value,
      }

      Commons.axiosInstance
        .put(Commons.apiDailyCheck + "/check", putData)
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successUpdateMsg)
            setDailyCheck(prevState => ({ ...prevState, [key]: value }))
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 403) {
            message.warning(Commons.errorPermissionMsg)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          hideLoadingCheckButtonSpin()
        })
    }
  }

  const updateInit = data => {
    if (isMountedRef.current) {
      if (checkDailyComplete()) {
        showLoadingButtonSpin()

        const putData = {
          HATTCHI_DT: dailyCheck.HATTCHI_DT,
          UNTEN_WORK_START_TIME: moment(data.startDate)
            .hour(moment(data.startTime).hour())
            .minute(moment(data.startTime).minute())
            .format("YYYY-MM-DD HH:mm"),
          START_MILEAGE: data.startMileage,
          WEATHER: data.startWeather,
        }

        Commons.axiosInstance
          .put(Commons.apiDailyCheck + "/init", putData)
          .then(response => {
            if (response.status === 200) {
              message.success(Commons.successCreateMsg)
              history.push(Commons.homeRoute)
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              message.warning(Commons.errorSessionMsg)
              history.push(Commons.loginURL)
            } else if (error.response.status === 403) {
              message.warning(Commons.errorPermissionMsg)
            } else if (error.response.status === 500) {
              message.error(Commons.errorSystemMsg)
            }
          })
          .finally(() => {
            hideLoadingButtonSpin()
          })
      } else {
        message.warning(Commons.errorCheckMsg)
      }
    }
  }

  const updateEnd = data => {
    if (isMountedRef.current) {
      showLoadingButtonSpin()

      const putData = {
        HATTCHI_DT: dailyCheck.HATTCHI_DT,
        UNTEN_WORK_END_TIME: moment(data.endDate)
          .hour(moment(data.endTime).hour())
          .minute(moment(data.endTime).minute())
          .format("YYYY-MM-DD HH:mm"),
        END_MILEAGE: data.endMileage,
        REFUELING_AMOUNT: data.endRefill,
      }

      Commons.axiosInstance
        .put(Commons.apiDailyCheck + "/end", putData)
        .then(response => {
          if (response.status === 200) {
            message.success(Commons.successCreateMsg)
            history.push(Commons.homeRoute)
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else if (error.response.status === 403) {
            message.warning(Commons.errorPermissionMsg)
          } else if (error.response.status === 500) {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          hideLoadingButtonSpin()
        })
    }
  }

  const showLoadingButtonSpin = () => {
    setLoadingButtonSpin(true)
  }

  const hideLoadingButtonSpin = () => {
    setLoadingButtonSpin(false)
  }

  const showLoadingCheckButtonSpin = () => {
    setLoadingCheckButtonSpin(true)
  }

  const hideLoadingCheckButtonSpin = () => {
    setLoadingCheckButtonSpin(false)
  }

  const showCheckModal = () => {
    setCheckModalVisible(true)
  }

  const hideCheckModal = () => {
    setCheckModalVisible(false)
  }

  const touchHandlers = useSwipeable({
    onSwipedLeft: e => {
      if (
        // e.event?.originalTarget?.className === "ant-table-cell" &&
        Number(currentTabKey) < 4
      )
        setCurrentTabKey(Number(currentTabKey) + 1 + "")
    },
    onSwipedRight: e => {
      if (
        // e.event?.originalTarget?.className === "ant-table-cell" &&
        Number(currentTabKey) > 1
      )
        setCurrentTabKey(Number(currentTabKey) - 1 + "")
    },
    preventDefaultTouchmoveEvent: true,
  })

  const columns = [
    {
      key: "insPoint",
      title: "点検箇所",
      dataIndex: "insPoint",
      render: (insPoint, row, index) => {
        const obj = {
          children: insPoint,
          props: {},
        }

        if (row.key === "DAILY_CHECK_OUT_COLM_03") {
          obj.props.rowSpan = 4
        }

        if (row.key === "DAILY_CHECK_OUT_COLM_04") {
          obj.props.rowSpan = 0
        }

        if (row.key === "DAILY_CHECK_OUT_COLM_05") {
          obj.props.rowSpan = 0
        }

        if (row.key === "DAILY_CHECK_OUT_COLM_06") {
          obj.props.rowSpan = 0
        }

        return obj
      },
    },
    {
      key: "insItem",
      title: "点検項目",
      dataIndex: "insItem",
    },
    {
      key: "status",
      title: "良否",
      dataIndex: "status",
      width: 115,
      align: "center",
      render: status => (
        <Row>
          {status.value === "0" ? (
            <Col className="text-center">
              <CustomRedButton
                loading={loadingCheckButtonSpin}
                size="large"
                className="m-1"
                icon={<CloseOutlined style={{ color: "#FFF" }} />}
              />
              <CustomGreyButton
                loading={loadingCheckButtonSpin}
                size="large"
                className="m-1"
                onClick={() => updateCheck(status.key, "1")}
                icon={<CheckOutlined style={{ color: "#FFF" }} />}
              />
            </Col>
          ) : status.value === "1" ? (
            <Col className="text-center">
              <CustomGreyButton
                loading={loadingCheckButtonSpin}
                size="large"
                className="m-1"
                onClick={() => updateCheck(status.key, "0")}
                icon={<CloseOutlined style={{ color: "#FFF" }} />}
              />
              <CustomGreenButton
                loading={loadingCheckButtonSpin}
                size="large"
                className="m-1"
                icon={<CheckOutlined style={{ color: "#FFF" }} />}
              />
            </Col>
          ) : (
            <Col className="text-center">
              <CustomGreyButton
                loading={loadingCheckButtonSpin}
                size="large"
                className="m-1"
                onClick={() => updateCheck(status.key, "0")}
                icon={<CloseOutlined style={{ color: "#FFF" }} />}
              />
              <CustomGreyButton
                loading={loadingCheckButtonSpin}
                size="large"
                className="m-1"
                onClick={() => updateCheck(status.key, "1")}
                icon={<CheckOutlined style={{ color: "#FFF" }} />}
              />
            </Col>
          )}
        </Row>
      ),
    },
  ]

  return (
    <Card bordered={false} bodyStyle={{ padding: "12px" }}>
      {Object.keys(dailyCheck).length !== 0 ? (
        dailyCheck.UNTEN_WORK_START_TIME ? (
          dailyCheck.UNTEN_WORK_END_TIME ? (
            <Empty />
          ) : (
            <Card size="small" title="到着確認" type="inner">
              <Form
                form={endForm}
                name="endForm"
                onFinish={updateEnd}
                scrollToFirstError
                colon={false}
                initialValues={{
                  endDate: moment(dailyCheck.HATTCHI_DT).toDate(),
                  endTime: moment().toDate(),
                  endMileage: "0",
                  endRefill: "0",
                }}
                size="large"
              >
                <Row gutter={[8, 0]}>
                  <Col xs={{ span: 12 }}>
                    <Form.Item
                      label="到着日付"
                      name="endDate"
                      className="block"
                      rules={[
                        {
                          required: true,
                          message: "日付を入力してください",
                        },
                      ]}
                    >
                      {/* <DatePicker
                        placeholder="____/__/__"
                        className="block"
                        format="YYYY/M/D"
                        inputReadOnly
                      /> */}
                      <DatePickerM
                        mode="date"
                        title="到着日付"
                        format="YYYY-MM-DD"
                        locale={{
                          DatePickerLocale: {
                            year: "年",
                            month: "月",
                            day: "日",
                            hour: "時",
                            minute: "分",
                          },
                          okText: "確定",
                          dismissText: "取消",
                        }}
                      >
                        <List.Item arrow="down"></List.Item>
                      </DatePickerM>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 12 }}>
                    <Form.Item
                      label="到着時間"
                      name="endTime"
                      className="block"
                      rules={[
                        {
                          required: true,
                          message: "時間を入力してください",
                        },
                      ]}
                    >
                      {/* <TimePicker
                        placeholder="__:__"
                        className="block"
                        format="HH:mm"
                        onOpenChange={open =>
                          open
                            ? endForm.setFieldsValue({
                                endTime: "",
                              })
                            : ""
                        }
                        inputReadOnly
                      /> */}
                      <DatePickerM
                        mode="time"
                        title="到着時間"
                        locale={{
                          DatePickerLocale: {
                            year: "年",
                            month: "月",
                            day: "日",
                            hour: "時",
                            minute: "分",
                          },
                          okText: "確定",
                          dismissText: "取消",
                        }}
                      >
                        <List.Item arrow="down"></List.Item>
                      </DatePickerM>
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      label="到着時の走行距離"
                      name="endMileage"
                      className="block"
                      rules={[
                        {
                          required: true,
                          message: "走行距離を入力してください",
                        },
                      ]}
                    >
                      <Commons.NumericInput
                        placeholder="走行距離を入力してください"
                        addonAfter="km"
                      />
                    </Form.Item>
                  </Col>
                  <Col xs={{ span: 24 }}>
                    <Form.Item
                      label="給油"
                      name="endRefill"
                      className="block"
                      rules={[
                        {
                          required: true,
                          message: "給油を入力してください",
                        },
                      ]}
                    >
                      <Commons.NumericInput
                        placeholder="給油を入力してください"
                        addonAfter="ℓ"
                      />
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row justify="center" gutter={[8, 8]}>
                  <Col>
                    <Link to={Commons.homeRoute}>
                      <Button>戻る</Button>
                    </Link>
                  </Col>
                  <Col>
                    <Button
                      loading={loadingButtonSpin}
                      type="primary"
                      htmlType="submit"
                    >
                      到着登録
                    </Button>
                  </Col>
                </Row>
              </Form>
            </Card>
          )
        ) : (
          <Card size="small" title="出発確認" type="inner">
            <Form
              form={startForm}
              name="startForm"
              onFinish={updateInit}
              scrollToFirstError
              colon={false}
              initialValues={{
                startDate: moment(dailyCheck.HATTCHI_DT).toDate(),
                startTime: moment().toDate(),
                startMileage: "0",
              }}
              size="large"
            >
              <Row gutter={[8, 0]}>
                <Col xs={{ span: 12 }}>
                  <Form.Item
                    label="出発日付"
                    name="startDate"
                    className="block"
                    rules={[
                      {
                        required: true,
                        message: "日付を入力してください",
                      },
                    ]}
                  >
                    {/* <DatePicker
                      placeholder="____/__/__"
                      className="block"
                      format="YYYY/M/D"
                      inputReadOnly
                    /> */}
                    <DatePickerM
                      mode="date"
                      title="出発日付"
                      format="YYYY-MM-DD"
                      locale={{
                        DatePickerLocale: {
                          year: "年",
                          month: "月",
                          day: "日",
                          hour: "時",
                          minute: "分",
                        },
                        okText: "確定",
                        dismissText: "取消",
                      }}
                    >
                      <List.Item arrow="down"></List.Item>
                    </DatePickerM>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 12 }}>
                  <Form.Item
                    label="出発時間"
                    name="startTime"
                    className="block"
                    rules={[
                      {
                        required: true,
                        message: "時間を入力してください",
                      },
                    ]}
                  >
                    {/* <TimePicker
                      placeholder="__:__"
                      className="block"
                      format="HH:mm"
                      onOpenChange={open =>
                        open
                          ? startForm.setFieldsValue({
                              startTime: "",
                            })
                          : ""
                      }
                      inputReadOnly
                    /> */}
                    <DatePickerM
                      mode="time"
                      title="出発時間"
                      locale={{
                        DatePickerLocale: {
                          year: "年",
                          month: "月",
                          day: "日",
                          hour: "時",
                          minute: "分",
                        },
                        okText: "確定",
                        dismissText: "取消",
                      }}
                    >
                      <List.Item arrow="down"></List.Item>
                    </DatePickerM>
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label="出発時の走行距離"
                    name="startMileage"
                    className="block"
                    rules={[
                      {
                        required: true,
                        message: "走行距離を入力してください",
                      },
                    ]}
                  >
                    <Commons.NumericInput
                      placeholder="走行距離を入力してください"
                      addonAfter="km"
                    />
                  </Form.Item>
                </Col>
                <Col xs={{ span: 24 }}>
                  <Form.Item
                    label="出発時の天候"
                    name="startWeather"
                    className="block"
                    rules={[
                      {
                        required: true,
                        message: "天候を選択してください",
                      },
                    ]}
                  >
                    <Select placeholder="天候を選択してください">
                      {weathers.map(weather => (
                        <Option
                          key={weather[Commons.WEATHER_CODE]}
                          value={weather[Commons.WEATHER_CODE]}
                        >
                          {weather[Commons.WEATHER_VALUE]}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} className="text-center">
                  <Button
                    icon={
                      checkDailyComplete() ? (
                        <CheckCircleFilled style={{ color: "#a0d911" }} />
                      ) : (
                        <ExclamationCircleFilled style={{ color: "#fa8c16" }} />
                      )
                    }
                    onClick={showCheckModal}
                  >
                    チェックシート
                  </Button>
                </Col>
              </Row>
              <Divider />
              <Row justify="center" gutter={[8, 8]}>
                <Col>
                  <Link to={Commons.homeRoute}>
                    <Button>戻る</Button>
                  </Link>
                </Col>
                <Col>
                  <Button
                    loading={loadingButtonSpin}
                    type="primary"
                    htmlType="submit"
                  >
                    出発登録
                  </Button>
                </Col>
              </Row>
            </Form>
            <Modal
              title="チェックシート"
              visible={checkModalVisible}
              style={{ top: 20 }}
              onCancel={hideCheckModal}
              footer={null}
            >
              <div {...touchHandlers}>
                <Row justify="center">
                  <Col xs={24}>
                    <Tabs
                      defaultActiveKey="1"
                      activeKey={currentTabKey}
                      onTabClick={key => setCurrentTabKey(key)}
                    >
                      <TabPane tab="運転席" key="1">
                        <CustomTable
                          size="small"
                          columns={columns}
                          dataSource={
                            dailyCheck
                              ? Object.keys(dailyCheck)
                                  .filter(key =>
                                    checkSection1.keys.includes(key),
                                  )
                                  .map(key => {
                                    const index = checkSection1.keys.indexOf(
                                      key,
                                    )
                                    return {
                                      key: key,
                                      insPoint: checkSection1.insPoints[index],
                                      insItem: checkSection1.insItems[index],
                                      status: {
                                        key: key,
                                        value: dailyCheck[key],
                                      },
                                    }
                                  })
                              : []
                          }
                          bordered={true}
                          pagination={false}
                        />
                      </TabPane>
                      <TabPane tab="エンジンルーム" key="2">
                        <CustomTable
                          size="small"
                          columns={columns}
                          dataSource={
                            dailyCheck
                              ? Object.keys(dailyCheck)
                                  .filter(key =>
                                    checkSection2.keys.includes(key),
                                  )
                                  .map(key => {
                                    const index = checkSection2.keys.indexOf(
                                      key,
                                    )
                                    return {
                                      key: key,
                                      insPoint: checkSection2.insPoints[index],
                                      insItem: checkSection2.insItems[index],
                                      status: {
                                        key: key,
                                        value: dailyCheck[key],
                                      },
                                    }
                                  })
                              : []
                          }
                          bordered={true}
                          pagination={false}
                        />
                      </TabPane>
                      <TabPane tab="外回り" key="3">
                        <CustomTable
                          size="small"
                          columns={columns}
                          dataSource={
                            dailyCheck
                              ? Object.keys(dailyCheck)
                                  .filter(key =>
                                    checkSection3.keys.includes(key),
                                  )
                                  .map(key => {
                                    const index = checkSection3.keys.indexOf(
                                      key,
                                    )
                                    return {
                                      key: key,
                                      insPoint: checkSection3.insPoints[index],
                                      insItem: checkSection3.insItems[index],
                                      status: {
                                        key: key,
                                        value: dailyCheck[key],
                                      },
                                    }
                                  })
                              : []
                          }
                          bordered={true}
                          pagination={false}
                        />
                      </TabPane>
                      <TabPane tab="その他" key="4">
                        <CustomTable
                          size="small"
                          columns={columns}
                          dataSource={
                            dailyCheck
                              ? Object.keys(dailyCheck)
                                  .filter(key =>
                                    checkSection4.keys.includes(key),
                                  )
                                  .map(key => {
                                    const index = checkSection4.keys.indexOf(
                                      key,
                                    )
                                    return {
                                      key: key,
                                      insPoint: checkSection4.insPoints[index],
                                      insItem: checkSection4.insItems[index],
                                      status: {
                                        key: key,
                                        value: dailyCheck[key],
                                      },
                                    }
                                  })
                              : []
                          }
                          bordered={true}
                          pagination={false}
                        />
                      </TabPane>
                    </Tabs>
                  </Col>
                  <Divider />
                  <Col>
                    <Button onClick={hideCheckModal}>閉じる</Button>
                  </Col>
                </Row>
              </div>
            </Modal>
          </Card>
        )
      ) : (
        <Empty />
      )}
    </Card>
  )
}

export default withRouter(Home)
