import React, { useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import {
  Button,
  Card,
  Carousel,
  Col,
  Descriptions,
  Divider,
  Empty,
  Row,
} from "antd"
import { DatePicker, List } from "antd-mobile"
import moment from "moment"
import "moment/locale/ja"
import * as Commons from "common/common"

moment.locale("ja")

const Home = props => {
  const {
    deliveries,
    dailyCheck,
    fetchData,
    setCurrentDate,
    currentDate,
  } = props

  useEffect(fetchData, [])

  return (
    <Card bordered={false} bodyStyle={{ padding: "12px" }}>
      <List className="date-picker-list" style={{ backgroundColor: "white" }}>
        <DatePicker
          mode="date"
          title="日付"
          format="YYYY-MM-DD"
          locale={{
            DatePickerLocale: {
              year: "年",
              month: "月",
              day: "日",
              hour: "時",
              minute: "分",
            },
            okText: "確定",
            dismissText: "取消",
          }}
          maxDate={moment().toDate()}
          value={moment(currentDate).toDate()}
          onChange={date => {
            setCurrentDate(date)
            fetchData(date)
          }}
        >
          <List.Item arrow="down">配車板</List.Item>
        </DatePicker>
      </List>
      <Divider />
      <Card
        size="small"
        title={
          Object.keys(dailyCheck).length !== 0
            ? dailyCheck.UNTEN_WORK_START_TIME
              ? dailyCheck.UNTEN_WORK_END_TIME
                ? ""
                : "配車情報（未到着）"
              : "配車情報（未出発）"
            : ""
        }
        type="inner"
      >
        {deliveries.length > 0 ? (
          <Carousel swipe={true} dots={true} infinite={false}>
            {deliveries.map((delivery, i) => (
              <Row key={delivery.MACH_DEN_NO || i}>
                <Descriptions bordered>
                  <Descriptions.Item label="荷主">
                    {delivery.NINUS_HIGHWAY_TK || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="発地">
                    {delivery.Cargo.HATTCHI_PLACE || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="着地">
                    {delivery.Cargo.THAKUCHI_PLACE || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="発着荷主名">
                    {delivery.Cargo.NINUS_RN || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="品名">
                    {delivery.HIN_NM || "-"}
                  </Descriptions.Item>
                  {/* <Descriptions.Item label="個数">-</Descriptions.Item>
                  <Descriptions.Item label="重量">-</Descriptions.Item>
                  <Descriptions.Item label="単価">-</Descriptions.Item> */}
                  <Descriptions.Item label="No.">
                    {delivery.MACH_DEN_NO || "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="出発日付">
                    {dailyCheck.UNTEN_WORK_START_TIME
                      ? moment
                          .utc(dailyCheck.UNTEN_WORK_START_TIME)
                          .format("YYYY-MM-DD HH:mm")
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="出発時距離">
                    {dailyCheck.START_MILEAGE !== undefined &&
                    dailyCheck.START_MILEAGE !== null
                      ? dailyCheck.START_MILEAGE
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="到着日付">
                    {dailyCheck.UNTEN_WORK_END_TIME
                      ? moment
                          .utc(dailyCheck.UNTEN_WORK_END_TIME)
                          .format("YYYY-MM-DD HH:mm")
                      : "-"}
                  </Descriptions.Item>
                  <Descriptions.Item label="到着時距離">
                    {dailyCheck.END_MILEAGE !== undefined &&
                    dailyCheck.END_MILEAGE !== null
                      ? dailyCheck.END_MILEAGE
                      : "-"}
                  </Descriptions.Item>
                </Descriptions>
              </Row>
            ))}
          </Carousel>
        ) : (
          <Empty />
        )}
        <Row className="mt-4" justify="center">
          <Col>
            {Object.keys(dailyCheck).length !== 0 ? (
              dailyCheck.UNTEN_WORK_START_TIME ? (
                dailyCheck.UNTEN_WORK_END_TIME ? (
                  ""
                ) : (
                  <Link to={Commons.homeDetailRoute}>
                    <Button type="primary">到着確認</Button>
                  </Link>
                )
              ) : (
                <Link to={Commons.homeDetailRoute}>
                  <Button type="primary">出発確認</Button>
                </Link>
              )
            ) : (
              ""
            )}
          </Col>
        </Row>
      </Card>
    </Card>
  )
}

export default withRouter(Home)
