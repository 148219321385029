import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import { Topbar } from "./components"
import { Layout, message, Spin } from "antd"
import moment from "moment"
import "moment/locale/ja"
import * as Commons from "common/common"

const Main = props => {
  const { children, history } = props
  const { Header, Content } = Layout
  const isMountedRef = Commons.useIsMountedRef()

  const [isPageLoading, setIsPageLoading] = useState(false)
  const [driver, setDriver] = useState("")
  const [currentDate, setCurrentDate] = useState(moment())
  const [deliveries, setDeliveries] = useState([])
  const [dailyCheck, setDailyCheck] = useState({})

  const showLoadingPageSpin = () => {
    setIsPageLoading(true)
  }

  const hideLoadingPageSpin = () => {
    setIsPageLoading(false)
  }

  const fetchData = useCallback(
    (selectedDate = undefined) => {
      showLoadingPageSpin()

      const params = {
        params: {
          start: moment(selectedDate ? selectedDate : currentDate).format(
            "YYYY-MM-DD",
          ),
          end: moment(selectedDate ? selectedDate : currentDate).format(
            "YYYY-MM-DD",
          ),
        },
      }

      Commons.axiosInstance
        .get(Commons.apiDelivery, params)
        .then(response => {
          if (isMountedRef.current) {
            setDeliveries(response.data || [])

            if (response.data.length > 0) {
              if (response.data[0].HATTCHI_DT) {
                const params2 = {
                  params: {
                    HATTCHI_DT: response.data[0].HATTCHI_DT,
                  },
                }

                Commons.axiosInstance
                  .get(Commons.apiDailyCheck, params2)
                  .then(response => {
                    if (isMountedRef.current) {
                      setDailyCheck(response.data || {})
                    }
                  })
              }
            }
          }
        })
        .catch(error => {
          if (error.response.status === 403) {
            message.warning(Commons.errorSessionMsg)
            history.push(Commons.loginURL)
          } else {
            message.error(Commons.errorSystemMsg)
          }
        })
        .finally(() => {
          hideLoadingPageSpin()
        })
    },
    [isMountedRef, currentDate, history],
  )

  useEffect(() => {
    if (localStorage.getItem(Commons.WEB_ID))
      setDriver(localStorage.getItem(Commons.WEB_ID))
    // eslint-disable-next-line
  }, [])

  const childrenWithProps = React.Children.map(children, element =>
    React.cloneElement(element, {
      showLoadingPageSpin: showLoadingPageSpin,
      hideLoadingPageSpin: hideLoadingPageSpin,
      deliveries: deliveries,
      dailyCheck: dailyCheck,
      setDailyCheck: setDailyCheck,
      fetchData: fetchData,
      setCurrentDate: setCurrentDate,
      currentDate: currentDate,
    }),
  )

  return (
    <div className="flex flex-col w-full min-h-full">
      <Spin spinning={isPageLoading} style={{ maxHeight: "100vh" }}>
        <Layout className="min-h-full">
          <Layout className="site-layout min-h-screen">
            <Header className="site-layout-sub-header-background p-0 shadow">
              <Topbar driver={driver} />
            </Header>
            <Content className="site-layout-background m-2 bg-white border-solid border border-gray-400 shadow">
              {childrenWithProps}
            </Content>
          </Layout>
        </Layout>
      </Spin>
    </div>
  )
}

export default withRouter(Main)
